import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {useCssVar} from "@vueuse/core";
import {$themeColors} from "../themeConfig";

export const notify = function (props) {
    Vue.prototype.$toast(
        {
            component: ToastificationContent,
            props: {
                title: props.title,
                icon: props.icon ? props.icon : "",
                text: props.text,
                variant: props.color
            },
        },
        {
            position: "bottom-right",
            timeout: props.time ? props.time : 4000
        })
}

const copyLink = function (type, id) {
    let base_url = ''
    const this_ = this
    if (process.env.VUE_APP_USE_TOKEN) {
        base_url = `https://hortilio.fr/${type}/${id.toString()}`
        if (window.cordova) {
            window.cordova.plugins.clipboard.copy(base_url,
                () => {
                    this_.$notify({
                        title: 'Lien copié',
                        text: 'Le lien vers la fiche a bien été copié',
                        color: 'primary',
                        time: 4000,
                    })
                }, () => {
                    this_.$notify({
                        title: 'Lien non copié',
                        text: "Le lien n'a pas pu être copié",
                        color: 'danger',
                        time: 4000,
                    })
                })
        }
    } else {
        base_url = `${window.location.origin}/${type}/${+id.toString()}`
        const this_ = this
        navigator.clipboard.writeText(base_url).then(() => {
            this_.$notify({
                title: 'Lien copié',
                text: 'Le lien vers la fiche a bien été copié',
                color: 'primary',
                time: 4000,
            })
            /* clipboard successfully set */
        }, () => {
            this_.$notify({
                title: 'Lien non copié',
                text: "Le lien n'a pas pu être copié",
                color: 'danger',
                time: 4000,
            })
            /* clipboard write failed */
        })
    }
}

export const theme = function (json) {
    const root = document.documentElement
    for (const clave in json) {
        root.style.setProperty(`--${clave}`, json[clave])
    }
}

const loading = function (value, message) {
    this.$store.commit('app/UPDATE_MESSAGE', value ? message : null)
    this.$store.commit('app/UPDATE_LOAD', value)
}

export const setColors = function (color_adaptation, mainLocalAuthority) {

    // let active_garden = rootState.garden.gardens.find(garden => {
    //     return garden.is_active
    // })
    //
    // if (active_garden) {
    //     mainLocalAuthority = state.subscriptions.find((localAuth) => {
    //         return localAuth.id === active_garden.local_authority
    //     })
    // }


    //Define original colors
    let colors = {
        'primary': "#00564b",
        'secondary': "#EAB27A",
        'success': "#28C76F",
        'info': "#271E4A",
        'warning': "#FF9F43",
        'danger': "#A4131C",
        'light': "#F8F8F8",
        'dark': "#5D5F63",
        'primary-light': null,
        'secondary-light': null,
        'success-light': null,
        'info-light': null,
        'warning-light': null,
        'danger-light': null,
    }

    //Adapt color if needed
    if (color_adaptation && mainLocalAuthority) {
        if (mainLocalAuthority.color) {
            colors['primary'] = mainLocalAuthority.color
        }
        if (mainLocalAuthority.secondary_color) {
            colors['secondary'] = mainLocalAuthority.secondary_color
        }
        if (mainLocalAuthority.dark_color) {
            colors['info'] = mainLocalAuthority.dark_color
        }
        if (mainLocalAuthority.secondary_color) {
            colors['warning'] = mainLocalAuthority.secondary_color
        }
        if (mainLocalAuthority.danger_color) {
            colors['danger'] = mainLocalAuthority.danger_color
        }
        if (mainLocalAuthority.grey_light_color) {
            colors['warning-light'] = mainLocalAuthority.grey_light_color
        }
    }

    //Todo: define color-light with a JS function to lighten or darken instead of using opacity
    colors['primary-light'] = colors['primary'] + "80"
    colors['secondary-light'] = colors['secondary'] + "20"
    colors['success-light'] = colors['success'] + "20"
    colors['info-light'] = colors['info'] + "20"
    //warning light modified by localAuth ?
    colors['warning-light'] = colors['warning-light'] ? colors['warning-light'] : colors['warning'] + "20"
    colors['danger-light'] = colors['danger'] + "20"

    //Define css properties
    theme(colors)

    //Define localStorage primary color to ensure right color during initial screen loading
    localStorage.primary_color = "#ffffff"

    //Define StatusBar
    let statusBarColor = (mainLocalAuthority && mainLocalAuthority.transparent_header && color_adaptation) ? colors["light"] : colors["primary"]
    document.getElementById('status-bar').content = statusBarColor
    if (window.cordova && window.StatusBar) {
        window.StatusBar.backgroundColorByHexString(statusBarColor)
        if (mainLocalAuthority && mainLocalAuthority.transparent_header) {
            window.StatusBar.styleDefault()
        } else {
            window.StatusBar.styleLightContent()
        }
    }

    // Define themeColors
    for (let color in colors) {
        $themeColors[color] = useCssVar(`--${color}`, document.documentElement).value.trim()
    }

    return colors
}

export const openLinkJaya = function (url) {
    if (process.env.VUE_APP_USE_TOKEN) {
        // Force using external browser
        window.cordova.InAppBrowser.open(url, '_system')
    } else {
        window.open(
            url,
            '_blank'
        );
    }
}

const parseDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
};

export const logout = function () {
    return new Promise((resolve, reject) => {
        if (process.env.VUE_APP_USE_TOKEN) {
            window.cordova.plugins.firebase.messaging.deleteToken()
                .then(() => {
                    const payload ={ id: this.$store.state.account.firebaseToken.id, active: false}
                    this.$store.dispatch("notifications/updateDevice", payload)
                        .then(() => {
                            this.$store.dispatch("account/logout")
                                .then(() => {
                                    this.$store.commit("account/UPDATE_FIREBASE_TOKEN", null)
                                    this.$store.commit("account/SET_FIREBASE_TOKEN_ID", null)
                                    resolve()
                                })
                        })
                        .catch(() => {
                            if (this.$store.state.account.firebaseToken.id === null) {
                                this.$store.dispatch("account/logout")
                                    .then(() => {
                                        this.$store.commit("account/UPDATE_FIREBASE_TOKEN", null)
                                        resolve()
                                    })
                            } else {
                                reject()
                            }
                        })
                })
                .catch(() => {
                    this.$store.dispatch("account/logout")
                        .then(() => {
                            this.$store.commit("account/UPDATE_FIREBASE_TOKEN", null)
                            this.$store.commit("account/SET_FIREBASE_TOKEN_ID", null)
                            resolve()
                        })
                        .catch(() => {
                            reject()
                        })
                })
        } else {
            this.$store.dispatch("account/logout")
                .then(() => {
                    resolve()
                })
                .catch(() => {
                    reject()
                })
        }
    })
}

export const fireLoginDone = function () {
    const evt = new Event("logindone", {"bubbles": true, "cancelable": false});
    document.dispatchEvent(evt)
}

export const pauseDifferentOnBoarding = function (componentName, store=null) {
    if (!store) {
        store = this.$store
    }
    const unpausedOnBoarding = []
    for (const [key, value] of Object.entries(store.state.account.onBoardingState)) {
        if (key !== componentName) {
            if (!value.paused) {
                unpausedOnBoarding.push(key)
                store.commit("account/PAUSE_ONBOARDING", key)
            }
        }
    }
    return unpausedOnBoarding
}
document.addEventListener('click', (e) => {
    if(e.target.id === 'paragraphLink') {
        e.preventDefault()
        openLinkJaya(e.target.href)
    }
})


Vue.prototype.$copyLink = copyLink
Vue.prototype.$theme = theme
Vue.prototype.$loading = loading
Vue.prototype.$notify = notify
Vue.prototype.$setColors = setColors
Vue.prototype.$openLinkJaya = openLinkJaya
Vue.prototype.$logout = logout
Vue.prototype.$fireLoginDone = fireLoginDone
Vue.prototype.$parseDate = parseDate
Vue.prototype.$pauseDifferentOnBoarding = pauseDifferentOnBoarding