import axios from "@/axios.js"

export default {

    /*
     * SEARCH
     */

    fetchLocalAuthority(context, id) {
        return new Promise((resolve, reject) => {
            axios.get("localAuthorities/" + id + "/")
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    fetchLocalAuthorities() {
        return new Promise((resolve, reject) => {
            axios.get("localAuthorities/")
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    /*
     * SUBSCRIPTION
     */

    fetchSubscription({ commit}) {
        return new Promise((resolve, reject) => {
            axios.get("localAuthorities/?subscriptions")
                .then(response => {
                    commit("SET_SUBSCRIPTIONS", response.data)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    subscribe({state, dispatch}, params) {
        return new Promise((resolve, reject) => {
            if (state.subscriptions.some(localAuthority => localAuthority.id === params.id)) {
                reject("already_subscribed")
            }
            let payload = {}
            axios.post("localAuthorities/" + params.id + "/subscription/", payload)
                .then(() => {
                    // Update subscription state via fetchSubscription action
                    dispatch("fetchSubscription").then(localAuthority => {
                        resolve(localAuthority)
                    }).catch(error => {
                        reject(error)
                    })
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    unsubscribe({state, commit}, params) {
        return new Promise((resolve, reject) => {
            let payload = {}
            // The user have no subscription
            if (state.subscriptions == null) {
                reject("not_subscribed")
            }

            axios.delete("localAuthorities/" + params.id + "/subscription/", {params: payload})
                .then(() => {
                    // Update subscription state via mutation
                    let newSubscriptions = [...state.subscriptions];
                    newSubscriptions.splice(newSubscriptions.findIndex(localAuthority => localAuthority.id === params.id), 1)
                    commit("SET_SUBSCRIPTIONS", newSubscriptions)
                    resolve(true)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    /*
     * ADMINISTRATION
     */

    fetchMemberOf({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("localAuthorities/?member")
                .then(response => {
                    if (response.data.length === 0) {
                        // The user is not a member of a local authority
                        resolve(null);
                    }

                    /*
                     * Currently, only one local authority per user is supported.
                     * We get the first one returned by the API if multiple.
                     * TODO manage multiple local authorities
                     */
                    let localAuthority = response.data[0]

                    // Get members
                    axios.get("localAuthorities/" + localAuthority.id + "/members/")
                        .then(members => {
                            localAuthority.members = members.data

                            // Return the local authority
                            commit("SET_MEMBER_OF", localAuthority)
                            resolve(localAuthority)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateInformation({dispatch, state}, localAuthority) {
        return new Promise((resolve, reject) => {

            let localAuthorityId = localAuthority.id;

            // The user is not a member of this local authority
            if (state.memberOf == null || state.memberOf.id !== localAuthorityId) {
                reject("not_member")
            }

            axios.patch("localAuthorities/" + localAuthorityId + "/", localAuthority)
                .then(() => {
                    // Update state via fetchMemberOf action
                    dispatch("fetchMemberOf").then(() => resolve(true)).catch(error => reject(error))
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    addMember({dispatch, state}, input) {
        return new Promise((resolve, reject) => {
            let localAuthorityId = input['localAuthorityId']

            // The user is not a member of this local authority
            if (state.memberOf == null || state.memberOf.id !== localAuthorityId) {
                reject("not_member")
            }

            let member = {
                'user_email': input['email'],
                'role': input['role']
            }
            axios.post("localAuthorities/" + localAuthorityId + "/members/", member)
                .then(() => {
                    // Update state via fetchMemberOf action
                    dispatch("fetchMemberOf").then(() => resolve(true)).catch(error => reject(error))
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    updateMember({dispatch, state}, input) {
        return new Promise((resolve, reject) => {
            let localAuthorityId = input['localAuthorityId']
            let memberId = input['memberId']

            // The user is not a member of this local authority
            if (state.memberOf == null || state.memberOf.id !== localAuthorityId) {
                reject("not_admin")
            }

            let member = {
                'role': input['role']
            }
            axios.patch("localAuthorities/" + localAuthorityId + "/members/" + memberId + "/", member)
                .then(() => {
                    // Update state via fetchMemberOf action
                    dispatch("fetchMemberOf").then(() => resolve(true)).catch(error => reject(error))
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    deleteMember({dispatch, state}, input) {
        return new Promise((resolve, reject) => {
            let localAuthorityId = input['localAuthorityId']
            let memberId = input['memberId']

            // The user is not a member of this local authority
            if (state.memberOf == null || state.memberOf.id !== localAuthorityId) {
                reject("not_member")
            }

            axios.delete("localAuthorities/" + localAuthorityId + "/members/" + memberId + "/")
                .then(() => {
                    // Update state via fetchMemberOf action
                    dispatch("fetchMemberOf").then(() => resolve(true)).catch(error => reject(error))
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getStats(context, id) {
        return new Promise((resolve, reject) => {
            axios.get("localAuthorities/" + id.toString() + "/infos/")
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getObservationsStats(context, data) {
      return new Promise((resolve, reject) => {
          axios.get("localAuthorities/" + data.id.toString() + "/observations/" + "?offset=" + data.page.toString() + "&limit=" + data.limit.toString())
              .then(response => {
                  resolve(response)
              })
              .catch(error => {
                  reject(error)
              })
      })
    },
    searchInvasives(context, params) {
        let id = params.id
        delete params.id
        return new Promise((resolve, reject) => {
            axios.get("localAuthorities/" + id.toString() + "/plants/", {params: params})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    detailsInvasive(context, params) {
        return new Promise((resolve, reject) => {
            axios.get("localAuthorities/" + params.id.toString() + "/plants/" + params.plantId.toString() + "/")
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    harvestInfos(context, params) {
        return new Promise((resolve, reject) => {
            let queryParams = {plant_id: params.plant_id}
            axios.get("localAuthorities/" + params.id.toString() + "/harvests/", {params: queryParams})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
