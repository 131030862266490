// axios
import axios from 'axios'

window.getApiHeaders = function () {
    let headers = {}

    // Add CSRF Token
    if (window.$cookies) {
        headers['X-CSRFToken'] = window.$cookies.get('csrftoken')
    }
    // Token Access for mobile app
    if (process.env.VUE_APP_USE_TOKEN) {
        let authToken = localStorage.getItem("jayauthToken")
        if (authToken) {
            headers['Authorization'] = "Token " + authToken
        }
    }

    return headers
}
let axiosHandler = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 5000,
    withCredentials: true,
    transformRequest: [function (data, headers) {

        for (const [key, value] of Object.entries(window.getApiHeaders())) {
            headers[key] = value
        }

        return JSON.stringify(data)
    }]
})
axiosHandler.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    return Promise.reject(error);
})

export default axiosHandler
